@charset "utf-8";
/* CSS Document */


:root {
	--primary-theme-color: #25aae1;
  }
  

html, body{	font-size: 14px;margin: 0;padding: 0;color: #333;cursor:auto; }
body { /*overflow-y: scroll; overflow-x: hidden;*/
  overflow-y: hidden;
  cursor: url(http://india.lss-supplychain.com/assets/customer/images/favicon/favicon-32.png), auto;
}
a:hover,a:focus{outline: none;text-decoration: none;}
label, .page-title,.section-title, /*td,*/ th{text-transform: capitalize;}
/*.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto{
	padding-right: 12px;
  padding-left: 12px;
}*/
/* Theme defaults */
a, .btn-link, .btn.btn-outline-primary {color: #049ad8; }
.btn.btn-outline-primary {color: #049ad8 !important; }
a:hover, .btn-link:hover{ color: #64c9f3; text-decoration: none;}
.btn.btn-outline-primary{border-color:#049ad8 !important;}
.btn.btn-outline-primary:hover, .btn.btn-outline-primary:focus{color:#fff !important;}
.btn.btn-outline-secondary:hover{ background-color: #6c757d;}
.btn-theme-primary{background-color: #25aae1;border-color: #25aae1;}
.btn-theme-primary:hover, .btn.btn-outline-primary:hover,
.btn-theme-primary:active, .btn.btn-outline-primary:active,
.btn-theme-primary:focus, .btn.btn-outline-primary:focus{background-color: #049ad8 !important; border-color: #049ad8;outline: none; color: #fff;}
.page-title{ font-size: 2.5rem; font-weight:700;margin: 2rem 0;}
.section-title{display: flex;	align-items: center;}
.section-title a{margin-right: .3rem; margin-left: auto;}
.section-btns{ margin-left: auto;}
.section-title h5{margin: 0;line-height: 1.5;max-width: 70%;
	overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
	.section-title h6{
	/*font-size: 1.2rem 1.5rem;*/ margin: 0;line-height: 1.3;max-width: 70%;
	overflow: hidden;text-overflow: ellipsis;white-space: nowrap;
}
.border-secondary {
  border-color: rgba(0,0,0,.1) !important;
}
.section-title .text-normal {
  font-weight: 400;
}
.section-title a:first-child{margin-right: unset;}
.collapse-title{color:#333;}
.secondary-title{font-size: 1.5rem; }
.p-b-30{padding-bottom:30px}
.navbar-toggle{border-color: #fff;}
.b-0{border: 0;}
.br-0, .btn, input[type="text"], .form-control, .input-group-text, select,
.custom-select, .custom-file-label, .custom-file-label::after{border-radius:0;}
.br-1{ border-radius: .25rem;}
[type=button]:not(:disabled), 
[type=reset]:not(:disabled), 
[type=submit]:not(:disabled), 
button:not(:disabled), button, [type="button"]{outline: none;}
.navbar-toggle .icon-bar{background-color: #fff;}
.bb-1{border-bottom-width: 1px !important;}
.bg-theme-light{background: #f2f2f2/*#f4faff*/;}
.bg-theme-primary, .badge-primary{ background:#25aae1 !important; }
.bg-theme-lighten50 {background:#81daff !important;}
.bg-theme-lighten25 {background:rgba(218, 243, 253, 0.07) !important;}
.bg-theme-secondary{ background-color: #6d6e71; }
.text-theme-primary{ color: #25aae1 !important; }
.border-theme-primary{border-color: #25aae1;}
.border-theme-primary-light{border-color: #47c3f6;}
.bb-primary-light{border-bottom: 1px solid #47c3f6 !important;}
.datepicker {padding: 0 !important;}
.datepicker-dropdown {padding: 4px !important;}
.b-bottom-secondary{border-bottom: 1px solid rgba(0,0,0,.125) !important;}
.b-bottom-primary{border-bottom: 1px solid #049ad8 !important;}
.bg-none{background-color: transparent !important; }
td > div.b-bottom-primary{ min-height: 23px;}
.btn-link:hover, .btn-link:active, .btn-link:focus{ text-decoration: none; }
.card, *{ border-radius: 0; }
.drpw-5{width: 150px;}
.cstm-fieldset{	padding: /*0*/ .5rem 1rem; border: solid 1px /*#83cfee*/ #dcdedf;}
.cstm-fieldset legend{font-size: inherit; padding: 0 .5rem; width: auto; margin-bottom: 0;}
.cstm-fieldset legend label{margin: 0;}
.mandetory-star{ position: relative;}
.mandetory-star::after{ 
    position: absolute; 
	right: -10px;
	color: red;
	font-size: 120%;
	content: '*';
}
.text-elipsis, td{ overflow:hidden; text-overflow: ellipsis; white-space: nowrap; }
/* td, th{ max-width: 100px;} */
td:last-child{ max-width: unset;}

.mw-100{ max-width: 100px !important;}
.mw-50 { max-width: 50px !important; }
.mw-70 { max-width: 70px !important; }
.mw-90 { max-width: 90px !important; }
.min-w-90 { min-width: 90px !important; }
.mw-30 {  max-width: 30px !important; }
.mw-110{ max-width: 110px !important;}
.w-100px{ width: 100px;}
.w-60{ width: 60px; }
.mw-100px{ max-width: 100px !important;}
.mw-120{ max-width: 120px;}
.mw-130{ max-width: 130px;}
.mw-170 { max-width: 150px;}
.minw-170 { min-width: 170px; }
.mw-200{ max-width: 200px;}
.mw-300{ max-width: 300px;}
.mw-10 { max-width: 10px !important;}
.w-available{
	width: -webkit-fill-available;
	width: -moz-available;
	width: -o-available;
	width: -ms-available;
	width: available;
}
.max-height-170 {
  max-height: 170px;
}
.max-height-300 {
  max-height: 300px;
}
.fa, .fa::before {
  color: inherit !important;
}
.list-group{max-height: 201px; overflow: auto;}
.table {margin-bottom: 0;}
.table-scroll-vertical{ overflow-y: auto; max-height: 240px;}
.table-scroll-vertical table{ border-collapse: separate; }
.table-scroll-vertical tr.stick-me { 
	position: -webkit-sticky;
  	position: sticky;
	z-index: 1;
}
.btn-group-sm>.btn, .btn-sm{ padding: .18rem .6rem;}
/* #latestPo_filter{display: none;} */
.py-4_5{
    padding-top: 2rem!important;
    padding-bottom: 2rem!important;
}
.mb-4_5{margin-bottom: 2rem;}
/* #main{min-height: 100vh; position: relative;padding-bottom: 45px;} */
#main{min-height: 100%; position: relative;}
.coming-soon-txt{ opacity: .7; }

/* ==================================================
    CSS BY Nikhil
================================================== */
.date-dropdown{
		padding: 10px 6px;
		font-size: 14px;
		font-weight: normal;
		line-height: 1;
		color: #555;
		text-align: center;
		background-color: #eee;
		border: 1px solid #ccc;
		border-radius: 0px 4px 4px 0px;
}
.custom-button{
    width: 100%;
    background-color:#25aae1;
    border:none;
    color:#fff;
}
.custom-button:hover{ background-color:#009DDE; color:#fff;}
.login-img{border-right:.7px solid #25aae1; margin-right:10px;padding:20px;}
.login-form{padding:20px;}
/* .login-img img{ margin-top:13%;} */
.links{font-weight:bold; font-size:14px;padding-right:4px;padding-left:4px;}
.links a{color:#707070;}
.nav-items{ background-color:#25aae1;color:#fff;}
.change-color{color:#fff;}
.heading{
    margin-top:2%;
    margin-bottom:3%;
    border:1px solid grey;
    border-radius:5px;
    color:#007feb;
    padding:20px 30px 20px 30px;
    background-color:#def5fd;
}
.announsment{background-color:#f4faff;}
/* .latestPO{ margin-top:4%; } */
.tables{ background-color:#fff; border:1px solid grey; color:#007feb;}
.icons{
    background-color:#e94949;
    padding:5px 15px;
    border-radius:3px 3px 3px 0;
    font-size:15px;
    color:#fff;
    width: 36px;
    display: inline-block;
    margin-right: 10px; 
}
.icons::after{
    content: "";
    width: 0;
    height: 0;
    display:inline-block;
    border-top: 10px solid #e94949;
    vertical-align: bottom;
    margin-bottom: -13px;
    margin-left:-20px;
    border-right: 10px solid transparent;
}
.indents{text-indent: 30px;}
.colors{background:#fff;}
.icon-bar{color:#007feb;}
.title-center{color:#707070; text-align: center;}
/*------My Profile--------*/
.custom-file {cursor: pointer;}
.custom-file-control {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    line-height: 1.5;
}
.cstm-only-ic{ width: 34px; }
/*.cstm-only-ic { width: 40px; }*/
/*-------account settings------*/
.nav-element{border:0.5px solid #dee2e6;border-radius:5px 5px 0 0;}
.tab-title{color:#007FEB;}
.tab-links{ color:#707070;font-weight:bold;}
.custom-tab{
    border-left:1px solid #dee2e6;
    border-right:1px solid #dee2e6;
    border-bottom:1px solid #dee2e6;
}
.tab-table{background-color:#fff; border:1px solid #dee2e6;}
.icon-btn{ padding: 4px 21px;}

.input-group-check{ display:none;}
.custom-check {
    display: block;
    position: relative;
    padding-left: 13px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .custom-check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 21px;
    width: 21px;
    background-color: #eee;
  }
  .custom-check:hover input ~ .checkmark {-color: #ccc;}
  .custom-check input:checked ~ .checkmark {background-color: #2196F3;}
  .checkmark:after {content: ""; position: absolute;display: none;}
  .custom-check input:checked ~ .checkmark:after {display: block;}
  .custom-check .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  /*-------po ----------*/
  .file {position: relative;overflow: hidden;}
  .file>input[type="file"] {
    position: absolute;
    font-size: 50px;
    opacity: 0;
    right: 0;
    top: 0;
  }
  .print-btn-text{ float: left; margin-right: 8px;}
/*---------Add Items----------*/
.custom-form-control{
    /* border:none; */
    position: relative;
    bottom: 7px;
}
.form-control:focus{
    color: #495057;
    background-color: #fff;
    border-color: unset !important;
    outline: 0;
    box-shadow: none !important;
}
.up-down-arrow{
    font-size:16px;
    float:right;
    font-weight:normal;
    cursor:pointer;
    opacity:0.6;
}
.thead-width tr th{ min-width:95px;}
.powered-by img{ height:39px;}
.powered-by h6{color:#707070;}
.lss-logo img{height:60px;}

.shipment-mode-icn{color:#6c757d; font-size:1.5rem; padding:5px;}
.shipment-mode-td{white-space: nowrap;}
.drpw-PO{min-width: 114px;}
.link{width:66px;}
.cstm-table{ padding:.25rem; margin-bottom:0px;}
.cstm-table td{padding:.08rem;}
.table td, table.dataTable tbody td/*, .table th*/ {padding: .2rem/*.45rem*/ .75rem; }
.table td, .table th{vertical-align: middle;}

.profile-tabs .nav-item.show .nav-link, .profile-tabs .nav-link.active, .profile-tabs .nav-link.active:hover {
	color: #25aae1;
	background-color:transparent !important;
	border-color:transparent;
	border-bottom:2px solid #25aae1;
}
.profile-tabs .nav-item a{color:#495057;}
.add-email{display:none;}
#port-of-loading{display:none;}
.add-portOfLoading{color:#25aae1;cursor:pointer;}
.profile-tabs .nav-sub-menu a{display: block; color:#fff;}
.profile-tabs .dropdown-menu{
    background: #25aae1;
    color:#fff;
    border: 0;
    left: unset;
    top:2px;
 right: 0;}
 .card-document-list{display:none;}
 .custom-nav-tab .nav-link:hover { border-color: transparent; color: #25aae1; }
 .site-logo img{max-height:70px;}

 /*Css added 06-10-2020*/

.border-primary {
  border: 1px solid var(--primary-theme-color);
}

.border-bottom-primary:first-child {
  border-bottom: 1px solid var(--primary-theme-color);
}

.hr-primary {
  border-top: 1px solid var(--primary-theme-color);
}

.title-primary {
  color: var(--primary-theme-color);
}

/*Design-1*/
.fix-height {
  height: 211px;
}
/*.fix-height {
  max-height: 210px;
}*/

  .vertical-scroll{
    overflow-y:scroll;
}

.overflow-unset{
    overflow:unset !important;
}

.ship-intrans-bx-outer .ship-intrans-bx{
  border-right: 1px solid rgba(0,0,0,.125);
}
.ship-intrans-bx-outer .ship-intrans-bx:last-child{
  border: none;
}
.btn-orange {
  background-color: #FFA500;
  color: #fff;
}
  .btn-orange:hover {
    background-color: #FF8C00;
    color: #fff;
  }
/*.demo .rounded:last-child .border-right-1 {
  border-right: 0px;
}*/

.custom-class {
 margin-top:5%;
}

/*.form-control:disabled, .form-control[readonly] {
  background-color:#f9f9f9;
}*/

.w-10{
    width:100px !important;
}
.btn-min-width{
    min-width:35px;
}

/* CSS For Navigation Start */
.toggle{
	font-size:1.5rem;
	border-radius:30px;
	display:inline-block;
	height:40px;
	width:40px;
	cursor:pointer;
	color:#ffffff;
	padding: .4rem;
	}
	.ico{
	margin-left:7px;
	margin-top:-4px;
	}
	.sidebar{
	position:fixed;
	width:400px;
	transition:all 0.5s;-webkit-transition:all 0.5s;
	right:-500px;
	background-color:#25aae1;
	font-size: 1rem;
	height:80vh;
	top:50px;
	z-index: 1022;
	justify-content: center;
	color: #000;
	
	}
	.sidebar h2{
	color:white;
	text-align:center;
	background: #25aae1;
	}

	.sidebar.active-sidebar{
	right:0px;
	height: 84vh;
	overflow-y: scroll;
	}
	.notibox{
	position:relative;
	}
	.cancel{
	position:absolute;
	right:7px;
	top:10px;
	cursor:pointer;
	padding:3px;
	padding-left:8px;
	padding-right:8px;
	border-radius:20px;
	}
	.cancel:hover{
	color:black;
	background-color:white;
	}
	.gone{
	display:none;
	}
	.header-icon{
		color: rgba(255,255,255,.9) !important;
		font-size: 1.4rem;
		padding: 0.5rem;
	}
	.no-wrap{
		white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	}
	.center {
		display: flex;
		justify-content: center;
		align-items: center;
	}
/* CSS For Navigation End */
.large-inupt-box:hover{
	height: 100px;
	overflow:auto;
	background-color:#ffffff;
	border:1px solid gray;
	cursor:pointer;
}

/* ==================================================
    CSS BY Nikhil ENDS Here
================================================== */
.view-more {
  cursor: pointer;
}
table, table .dt-responsive, .dt-responsive{ width: 100% !important; }
/* Overrided Classes */
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child::before, table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child::before{
	top: 3px /* 6px */;
}
/*table.dataTable thead .sorting::before, table.dataTable thead .sorting::after, table.dataTable thead .sorting_asc::before, table.dataTable thead .sorting_asc::after, table.dataTable thead .sorting_desc::before, table.dataTable thead .sorting_desc::after, table.dataTable thead .sorting_asc_disabled::before, table.dataTable thead .sorting_asc_disabled::after, table.dataTable thead .sorting_desc_disabled::before, table.dataTable thead .sorting_desc_disabled::after{
	 bottom: 0.35em;
} */
.dtr-data { display: inline-block; }
/* .dataTables_scrollHeadInner{width: calc(100% - 17px) !important;} */

.dataTables_wrapper .dataTables_paginate .paginate_button{ border: 1px solid transparent;padding: 0;}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button:active a:hover {border: 1px solid white; box-shadow: 0 0 #fff; }
.dataTables_wrapper .dataTables_paginate .paginate_button:active,
.dataTables_wrapper .dataTables_paginate .paginate_button:active a:hover{background-color: white;border: 1px solid white; box-shadow: 0 0 #fff;}

.dataTables_scrollBody {
  overflow-y: no-display !important;
  /*min-height: 140px;*/
  min-height: 292px;
  border: solid 1px #dedede;
  cursor: auto;
}
/*.dataTables_scrollBody > #addItems*/
.unsetted-min-height .dataTables_scrollBody {
  min-height: unset !important;
}

.dataTables_wrapper.no-footer .dataTables_scrollBody, table.dataTable thead th {
  border-bottom-color: #6c757d !important;
}


/* width */
::-webkit-scrollbar {
  width: 15px;
  height: 10px;
  position: absolute;
  left: 0;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px #dedede; 
  border-radius: 0;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #b5b5b5;/*#6a6b6b;*//*#6d6e71;*/
  border-radius: 0;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #acacac;
}
::-webkit-scrollbar-corner { display: none; height: 0px; width: 0px; }



/* .dataTables_wrapper .dataTables_scroll {
	border: 1px solid #ccc;
	margin: 1.5rem 0;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	position: absolute !important;
	left: 0 !important;
} */

/**********************************/
contenteditable{ border-bottom: solid 1px #049ad8; }
.navbar-toggler-icon{color:#fff;}

/* custom-table [data-table] */
.table-striped tbody tr:nth-of-type(2n+1){background-color: rgba(0,0,0,.02);}
.page-item.active .page-link{background-color: #25aae1;	border-color: #25aae1;}
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child::before,
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child::before{
	background-color: #25aae1;
}

/* Custom nav */
.custom-nav{ background-color:#25aae1; color:#fff;margin-bottom: 0;padding:0px; }
/* .custom-nav li > a{ color:#fff !important;padding: 15px 10px;}*/
.custom-nav li > a{ color:rgba(255,255,255,.9); padding: 15px 10px;}
/*.custom-nav li > a:hover, .custom-nav li > a:focus{ color:rgba(255,255,255,.6) !important;} */
.custom-nav li > a:hover, .custom-nav li > a:focus{background-color: #0081bf; color:rgba(255,255,255,1);}

.custom-nav .active > a, .active > a:hover, .active > a:focus{color:#fff !important;}
.custom-nav .active , .custom-nav .active > a:hover, .custom-nav .active > a:focus {
	background-color:  #0081bf/* #049ad8 #007feb*/ !important;
	border-radius: 0;
	color: #fff;
}

/* .navbar-brand{ padding:5px;} */
.navbar-brand img{ height:40px; background-color: #fff;}
.img-bordered{ border: solid 1px #f2f2f2;}
.user-prof-drop{ padding:9px !important;}
.user-prof-drop-menu{z-index: 1021;}
.custom-nav .dropdown-menu{
	background: #25aae1;
	border: 0;
	left: unset !important;
	right: 0;
}
/* .nav-item.dropdown::after{
	background-color: red;
	display: block;
	position: absolute;
	right: 15px; 
	top: 0;
	height: 5px;
	width: 5px;
} */
.nav-sub-menu {/*padding: 7px 14px;*/padding: 4px 14px;}
.nav-sub-menu:hover, .nav-sub-menu.active {background-color:  #1c91c9; }
.nav-sub-menu a { padding: 0 !important; display: inline-block; text-align: left; }
.nav-sub-menu a:hover, .nav-sub-menu a:focus { text-decoration: none; background-color: transparent !important; }
.user-prof-img{
	background-image: url(../images/user-prof-def.jpg);
	background-repeat: no-repeat;
	background-origin: border-box;
	background-position: center center;
	background-size: contain;
	height: 30px;
	width:30px;  
}

.nav-link{padding-right: 1rem !important; padding-left: 1rem !important;}
/* .nav-link{padding-right: .7rem !important; padding-left: .7rem !important;} */
.header-right{float: right;}
.user-supporrt-links > * { display: inline;  }
.user-supporrt-links a{color: #7e7f82;}
.user-supporrt-links a:hover{color: #25aae1;}

/*filter*/	
.filter-collapse a{text-decoration: none;}
.border-inset{
	-webkit-box-shadow: inset 0px 0px 6px 1px rgba(37,170,225,1);
-moz-box-shadow: inset 0px 0px 6px 1px rgba(37,170,225,1);
box-shadow: inset 0px 0px 6px 1px rgba(37,170,225,1);
}

/*form*/
.control-label{font-weight: 600;}
.show-rport-btn{background-color:#25aae1; border:0; color:#fff;}
.show-rport-btn:hover{background-color:#1895c8; border:0; color:#fff;}

/*report*/
div.table{border: solid 1px #707070; padding: 10px;background: #def5fd;}
.table-resp{width:100%; overflow:auto;}
.custom-table{
	margin-bottom: 0;
	border: solid 1px #707070;
	padding: 1px;	
}
.custom-table{ color: #2699fb}
.user-prof-drop-menu {
	top: 100% !important;
	right: 0 !important;
	left: unset !important;
	bottom: unset;
	border: 0;
	box-shadow: 0 0 4px 3px #0505054d;
	transform: translate3d(0px, 23px, 0px) !important;
}
.user-prof-drop-menu li{
	padding: 0 20px;
}
.user-prof-drop-menu li a{
	color: #333;
	display: block;
	padding: 15px 0;
	border-bottom: solid 1px #ececec;
}
.user-prof-drop-menu li:last-child a{
	border-bottom: solid 0px #ececec;
}
.user-prof-drop-menu li a:hover{ background-color:#fff !important;color:#808080 !important;text-decoration: none;}

#custom-dropdown,
#custom-dropdown .active,
#custom-dropdown .active > a:hover,
#custom-dropdown .active > a:focus{background-color:#fff !important; text-align: left;}
#custom-dropdown li > a{color:#707070 !important;text-align: left;}
.custom-file-input{overflow: hidden;}

/* announcement */
.announcement-ic{padding-top: 1.32rem;opacity: .6;}

#loader {
	background-color: #fff;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	width: 100%;
	height: 100%;	
	position: fixed;	
	opacity: 0.7;
	z-index: 1021;	
}

/* ######################### Shipment TABS ######################  */
.progress{min-width: 80px;}
.stage-clr-guide .list-group-item{padding: .25rem .55rem;	font-size: .86rem; border:0;}
.stage-clr-guide .list-group-item:last-child {padding-right: .02rem;}
.list-group-item span{ padding: 5px !important;}
/* .tab{ text-align: center; } */
.tab .nav-tabs{
	/* display: inline-block; */
	border-bottom: none;
	position: relative;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	flex-wrap: unset;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}
.tab .nav-tabs li{
	margin-bottom: 0;
	float: left;
	position: relative;
	display: block;
	flex: auto;
}
.tab .nav-tabs li a{
	display: block;
	padding: 20px;
	border: none;
	border-radius: 0;
	font-size: 1.1rem;
	font-weight: 600;
	color: #444;
	margin-right: 0;
	text-align: center;
	z-index: 1;
	transition: all 0.3s ease 0s;
}
.tab .nav-tabs li a i{
	display: block;
	font-size: 18px;
	color: #fff;
	margin-bottom: 15px;
}
.tab .nav-tabs li.active a,
.tab .nav-tabs li a:hover{
	border: none;
	background: transparent;
}
.tab .nav-tabs li.active a:before, .tab .nav-tabs li.active a:after {
	content: "";
	width: 0;
	border: none;
	position: absolute;
	bottom: -1px;
	left: 0;
	transition: all 0.3s ease 0s;
}
.tab .nav-tabs li.succeed a:before,
.tab .nav-tabs li.succeed a:after,
.tab .nav-tabs li.active a:before,
.tab .nav-tabs li.active a:after,
.tab .nav-tabs li a:before,
.tab .nav-tabs li a:after{
	content: "";
	width: 0;
	border-top: 3px solid #7e7f82;
	position: absolute;
	bottom: -2px;
	left: 2px;
	transition: all 0.3s ease 0s;
}
.tab .nav-tabs li.succeed a:before,
.tab .nav-tabs li.succeed a:after{
	border-top: 3px solid #00a6ff;
}
.tab .nav-tabs li.succeed a:after,
.tab .nav-tabs li.active a:after,
.tab .nav-tabs li a:after{
	left: auto;
	right: 2px;
}
.tab .nav-tabs li.succeed a:before,
.tab .nav-tabs li.active a:before,
.tab .nav-tabs li a:hover:before,
.tab .nav-tabs li.succeed a:after,
.tab .nav-tabs li.active a:after,
.tab .nav-tabs li a:hover:after{
	width: 50%;
}
.tab .tab-content{
	padding: 20px;
	margin-top: 0;
	border-radius: 0 0 5px 5px;
	border-top: 1px solid #d7d6d6;
	font-size: 15px;
	color: #757575;
	/* line-height: 30px; */
}
.tab .tab-content h3{
	font-size: 24px;
	margin-top: 0;
}
.step-ic{
	width: 50px;
	height: 50px;
	background: #cecece;
	border-radius: 100px;
	color: #fff;
	margin: 0 auto;
	display: block;
	position: relative;
}
.tab .nav-tabs li.succeed .step-ic{ background: #25aae1; }
.tab .nav-tabs li.active .step-ic{ background: #7e7f82; }
.step-ic i{
	position: absolute;
	top: 50%; left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	font-size: 17px;
	color: #fff;
}
.step-number{
	padding: 2px 0;
	display: block;
	font-weight: normal;
}
.step-title{
	padding: 2px 0;
	display: block;
	line-height: 1.3;
	/* font-weight: normal; */
}
.label-with-ic::after {
	font-family: "FontAwesome"; /*"Glyphicons Halflings"; */
	/* font: normal normal normal 14px/1 FontAwesome; */
	content: /*'\e142'*/ "\f0c6" !important;
}
#theme-opt{ z-index: 1021; position: fixed;top: 150px;right: 0;}
.theme-color-pallete{display: grid; height: 34px; width: 34px; border: solid 2px #e0e0e0d6;}
.theme-opt-1{ background-color: #6d6e71; }
.theme-opt-2{ background-color: #333; }

 .multiselect-native-select {
	position: relative;
  }
   .multiselect-native-select select {
	border: 0 !important;
	clip: rect(0 0 0 0) !important;
	height: 1px !important;
	margin: -1px -1px -1px -3px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	left: 50%;
	top: 30px;
  }
   .multiselect-container {
	position: absolute;
	list-style-type: none;
	margin: 0;
	padding: 0;
  }
   .multiselect-container .input-group {
	margin: 5px;
  }
   .multiselect-container li {
	padding: 0;
  }
   .multiselect-container li .multiselect-all label {
	font-weight: 700;
  }
	.multiselect-container li a {
	padding: 0;
  }
  .multiselect-container li a *,
  .multiselect-container li.active a:hover *{color: #007FEB;}
  .multiselect-container li a:hover *{background-color: #6c757d1f;}
   .multiselect-container li a label {
	margin: 0;
	height: 100%;
	cursor: pointer;
	font-weight: 400;
	padding: 3px 20px 3px 40px;
  }
  .multiselect-container li a label input[type=checkbox] {
	margin-bottom: 5px;
  }
  .multiselect-container li a label.radio {
	margin: 0;
  }
  .multiselect-container li a label.checkbox {
	margin: 0; display: block;
  }
  .multiselect-container li.multiselect-group label {
	margin: 0;
	padding: 3px 20px 3px 20px;
	height: 100%;
	font-weight: 700;
  }
  .multiselect-container li.multiselect-group-clickable label {
	cursor: pointer;
  }
  .btn-group .multiselect.btn {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	text-align: left;
	padding: .175rem .75rem;
	padding-left: 0;
  }
  /* .custom-select-sm .button, .custom-select-sm{padding: 1px;} */
  .multiselect-container label.checkbox {
	padding: 3px 20px 3px 40px;
  }
  .multiselect-container label.radio {
	padding: 3px 20px 3px 40px;
  }
  .multiselect-container li a label.checkbox input[type=checkbox] {
	margin-left: -20px;
	margin-right: 0;
  }
  .multiselect-container li a label.radio input[type=radio] {
	margin-left: -20px;
	margin-right: 0;
  }

	.multiselect.dropdown-toggle::after {
    right: 0;
    position: absolute;
    top: 43%;
	}

.tableSticky {position:relative;}
.tableSticky thead{position: sticky;top: 0;background: #fff;z-index: 1;}

/* success alert dimissable  */
/* .alert{display:none;} */
.sucs-msg-bx{
	position: absolute;
	top: 140px;
	right: 2px;
}
#loader {
	display: none;
}
/* .cntnr-smry-bx > * {height: 0;} */
.cntnr-smry-bx{
	visibility: hidden;
  height: 0;
  overflow-y: auto;
}


footer{width: 100%; position: absolute; bottom: 0;}
/* NG toaster */
/* position */
.toast-center-center {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
  }
  .toast-top-center {
	top: 0;
	right: 0;
	width: 100%;
  }
  .toast-bottom-center {
	bottom: 0;
	right: 0;
	width: 100%;
  }
  .toast-top-full-width {
	top: 0;
	right: 0;
	width: 100%;
  }
  .toast-bottom-full-width {
	bottom: 0;
	right: 0;
	width: 100%;
  }
  .toast-top-left {
	top: 12px;
	left: 12px;
  }
  .toast-top-right {
	top: 60px;
	right: 12px;
  }
  .toast-bottom-right {
	right: 12px;
	bottom: 12px;
  }
  .toast-bottom-left {
	bottom: 12px;
	left: 12px;
  }
  
  /* toast styles */
  .toast-title {
	font-weight: bold;
  }
  .toast-message {
	word-wrap: break-word;
  }
  .toast-message a,
  .toast-message label {
	color: #FFFFFF;
  }
  .toast-message a:hover {
	color: #CCCCCC;
	text-decoration: none;
  }
  .toast-close-button {
	position: relative;
	right: -0.3em;
	top: -0.3em;
	float: right;
	font-size: 20px;
	font-weight: bold;
	color: #FFFFFF !important;
	opacity: .7;
	text-shadow: 0 1px 0 #ffffff;
	/* opacity: 0.8; */
  }
  .toast-close-button:hover,
  .toast-close-button:focus {
	color: #fff;
	text-decoration: none;
	cursor: pointer;
	opacity: 1;
  }
  /*Additional properties for button version
   iOS requires the button element instead of an anchor tag.
   If you want the anchor version, it requires `href="#"`.*/
  button.toast-close-button {
	padding: 0;
	cursor: pointer;
	background: transparent;
	border: 0;
  }
  .toast-container {
	pointer-events: none;
	position: fixed;
	z-index: 999999;
  }
  .toast-container * {
	box-sizing: border-box;
  }
  .toast-container .ngx-toastr {
	position: relative;
	overflow: hidden;
	margin: 0 0 6px;
	padding: 15px 15px 15px 50px;
	width: 300px;
	border-radius: 3px 3px 3px 3px;
	background-position: 15px center;
	background-repeat: no-repeat;
	background-size: 24px;
	/* box-shadow: 0 0 12px #999999; */
	color: #FFFFFF;
  }
  .toast-container .ngx-toastr:hover {
	/* box-shadow: 0 0 12px #000000; */
	opacity: 1;
	cursor: pointer;
  }
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
  .toast-info {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
  }
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
  .toast-error {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
  }
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
  .toast-success {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
  }
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
  .toast-warning {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
  }
  .toast-container.toast-top-center .ngx-toastr,
  .toast-container.toast-bottom-center .ngx-toastr {
	width: 300px;
	margin-left: auto;
	margin-right: auto;
  }
  .toast-container.toast-top-full-width .ngx-toastr,
  .toast-container.toast-bottom-full-width .ngx-toastr {
	width: 96%;
	margin-left: auto;
	margin-right: auto;
  }
  .ngx-toastr {
	background-color: #030303;
	pointer-events: auto;
  }
  .toast-success {
	background-color: #51A351;
  }
  .toast-error {
	background-color: #BD362F;
  }
  .toast-info {
	background-color: #2F96B4;
  }
  .toast-warning {
	background-color: #F89406;
  }
  .toast-progress {
	position: absolute;
	left: 0;
	bottom: 0;
	height: 4px;
	background-color: #000000;
	opacity: 0.4;
  }
  .cursor-pointer{ cursor: pointer;}

.svg-inline--fa.fa-fw {
  width: .78em;
}
/*  changes by Deepika on 1/10/19 - for datatable */
button:not(:last-child), a.btn:not(:last-child) {
  margin-right: 0.25rem;
}

.dataTables_wrapper .dataTables_paginate .paginate_button, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  border: 1px transparent !important;
  background: transparent !important;
}

  .dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    background-color: #25aae1 !important;
    background: #25aae1 !important;
    color: #fff !important;
  }

.dataTables_wrapper .dataTables_paginate span .paginate_button.current, .dataTables_wrapper .dataTables_paginate span .paginate_button.current:hover, .dataTables_paginate .paginate_button.current:hover {
  color: #fff !important;
  border: 1px transparent !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  margin-left: -1px !important;
}

  .dataTables_wrapper .dataTables_paginate .paginate_button:active {
    outline: none !important;
    background-color: #25aae1 !important;
    background: #25aae1 !important;
    box-shadow: none !important;
    border: 1px transparent !important;
  }
.page-item.disabled .page-link {
  border-color: transparent;
}
.page-link {
  padding: .25rem .55rem;
  border: 1px transparent !important;
  outline: none !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:not(.disabled):hover {
  color: white !important;
  border: 1px transparent !important;
  background-color: #25aae1 !important;
  background: #25aae1 !important;
}
.dataTables_scrollHead {
  border-right: 1px solid #dedede !important;
  border-left: 1px solid #dedede !important;
}
table.dataTable {
  margin-top: 0px !important;
}



.text-underlined {
  text-decoration: underline;
}
.port-details-bx{
	height: 100%;
    max-height: 212px;
    overflow-y: auto;
}
.dataTables_scrollBody .dropdown-menu {
	top: 100% !important;
	will-change: unset !important;
    transform: unset !important;
}
.mw-70per {
  max-width: 70%;
}
.non-select {
 user-select: none;
}
/*.chart-legend{ width: 100% !important; overflow: hidden; }*/


.overflow-visible {
  overflow: visible !important;  
}
.custom-checkbox .custom-control-label::before{
	border-radius: 0;
  }

  .custom-control-label::before, .custom-control-label::after{
	width: 1.2rem;
	height: 1.2rem;
  }
  
  
/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
	  padding: 8px 8px 8px 50px;
	  width: 11em;
	}
	.toast-container .toast-close-button {
	  right: -0.2em;
	  top: -0.2em;
	}
  }
  @media all and (min-width: 241px) and (max-width: 480px) {
	.toast-container .ngx-toastr.div {
	  padding: 8px 8px 8px 50px;
	  width: 18em;
	}
	.toast-container .toast-close-button {
	  right: -0.2em;
	  top: -0.2em;
	}
  }
  @media all and (min-width: 481px) and (max-width: 768px) {
	.toast-container .ngx-toastr.div {
	  padding: 15px 15px 15px 50px;
	  width: 25em;
	}
  }
/* NG toaster ENDS here */

#scrollable-area{
	height: calc(100% - 100px);
	position: absolute;
	top: 52px;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-y: scroll;
  }

/* responsive media queries */
@media (min-width: 1920px){
	html, body{ font-size: 18px;}
}
@media (max-width: 1919px){
	html, body{ font-size: 16px;}
}
@media (max-width: 1366px){
	html, body{ font-size: 14px;}
}

@media (min-width: 1367px){
  .fix-height {
    height: 238px;
  }
}

@media (max-width: 1200px){
    .powered-by img{height:34px;}
    .drpw-PO{ width: 124px;}
    .link{width:69px;}
}
@media (min-width: 992px){
	#bolListDiv .dataTables_filter input.form-control.form-control-sm{
		max-width: 100px !important;
	}
	.custom-nav li > a{ text-align: center; }
	.custom-nav .nav-sub-menu a { text-align: left; display: block; }	
	.custom-nav .dropdown-menu{
		border-radius: 0;
		top: 110%;
		width: max-content;
	}
}
@media (max-width: 991px){
    /* .login-img img { margin-top: 27%; } */
    .title-center{ font-size:26px; }
    .drpw-PO{ width: 104px;}
}
/* @media (min-width: 768px){
	.custom-nav li > a{ text-align: center; }
	.custom-nav .nav-sub-menu a { text-align: left; display: block; }	
} */
@media (max-width: 767px){
	html, body{ font-size: 12px;}
    .nav-link { padding-right: 14px !important; padding-left: 14px !important;}
    .login-img{ margin:0;padding:0;}
    /* .login-img img { margin-top: 27%; } */
    .custom-button{font-size:14px;}
    .links{font-size:13px;}
    .title-center{font-size:18px; }
    .group-button button{font-size:12px; }
    .icon-btn{font-size:16px;}
    .tab-title{font-size:16px;}
		.thead-width tr th{ min-width:77px;}
		/* Overrided Classes */
		table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child::before, table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child::before{ top: 3px;}
		.stage-clr-guide .list-group{ display: block;	width: 100%; }
		.stage-clr-guide .list-group-item { display: inline-block; }
}
@media(min-width:564px){
    .center-form{
        position:absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
    }
}
@media (max-width: 575px){
	.col-xs-6{width:50%;}
	.col-xs-3{ width: 24.99%;}
	.col-xs-9{ width: 74.97%;}
	.col-xs-11{width: 91.63%;}
	/* .col-xs-* { float: left;} */
}
@media (max-width: 564px){   
    .login-img img { margin-top: 30px; max-width: 170px;}
    .login-img{ border-right: 0px; margin-right: 0px; padding:0px; }
    .links{ text-align: center;}
    .border-div{ border:none; }
    .powered-by img{height:30px;}
    .powered-by h6{font-size:10px;}
    .lss-logo img{height:50px;}
    .drpw-PO{ width: 105px;}
}

.tab .nav-tabs li.active a, .tab .nav-tabs li a:hover, .tab .nav-tabs li.active a:hover{
	color:#444 !important;
}

.form-control[readonly] {
  background-color: #fff;
}
  .form-control-pass + .fa {
	cursor: pointer;
	pointer-events: all;
}
.form-control-pass-ic {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	display: block;
	width: 34px;
	height: calc(1.5em + .75rem + 2px);
	line-height: 28px;
	text-align: center;
	pointer-events: none;
}

.my-class .modal-dialog {
  max-width: 1000px !important;
  min-width: 971px !important;
  width: 95% !important;

 
}
